<template>
  <div>UserEdit</div>
</template>

<script>
export default {
  name: 'UserEdit',
};
</script>

<style>

</style>
